import Head from "next/head"
import React from "react"
import { NavBar, SearchSection } from "../../components"

export default function SearchPage() {
  return (
    <>
      <Head>
        <title>배송 조회 - Today</title>
      </Head>
      <NavBar />
      <SearchSection title={"내 물품이\n어디까지 왔는지\n알아볼까요?"} />
    </>
  )
}
